<script lang="ts" setup>
import LabelText from '~/components/form-elements/LabelText.vue'
import type { RadioButtonChoice } from '~/types/form-elements'

const model = defineModel<any>()

const props = withDefaults(
  defineProps<{
    choices: RadioButtonChoice[]
    description?: string | null
    isRequired?: boolean
    isVertical?: boolean
    labelText?: string | null
    name: string
    withBlocks?: boolean
  }>(),
  { description: null, isRequired: false, isVertical: false, labelText: null, withBlocks: false }
)
</script>

<template>
  <div>
    <LabelText class="mb-2" :is-required="isRequired" :value="labelText" />

    <div class="radio-button-area-wrapper flex" :class="{ horizontal: !isVertical, vertical: isVertical }">
      <label
        v-for="x in choices"
        :key="String(x.value)"
        class="radio-button-area flex cursor-pointer items-center"
        :class="{ 'as-block': withBlocks, 'is-selected': model === x.value }"
      >
        <input v-model="model" :name="name" type="radio" :value="x.value">

        <span v-if="x.iconName" class="icon-area flex flex-col items-start">
          <span :class="{ 'flex items-center space-x-2': x.iconLabel }">
            <SvgIcon :name="x.iconName" />

            <span
              v-if="x.iconLabel"
              class="px-1 py-0.5 text-xs leading-[14px] rounded select-none"
              :style="{ backgroundColor: x.iconLabel.backgroundColor, color: x.iconLabel.color }"
            >
              {{ x.iconLabel.title }}
            </span>
          </span>

          <span v-if="x.iconDescription" class="description pre-wrap mt-2">{{ x.iconDescription }}</span>
        </span>
        <Status v-else-if="x.isStatus && x.statusColor" :color="x.statusColor" :title="x.labelText" />
        <code v-else-if="x.isCode">{{ x.labelText }}</code>
        <span v-else class="simple-area">
          <span class="simple">{{ x.labelText }}</span>
          <span v-if="x.description" class="description pre-wrap mt-2">{{ x.description }}</span>
        </span>
      </label>
    </div>

    <div v-if="description" class="mt-1 text-sm">
      {{ description }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:color';

@use 'assets/css/variables';

.radio-button-area-wrapper {
  &.horizontal {
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -8px;

    .radio-button-area {
      margin-bottom: 10px;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.vertical {
    align-items: flex-start;
    flex-direction: column;

    .radio-button-area {
      margin-bottom: 8px;
      margin-right: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.radio-button-area {
  color: variables.$main-gray-color;
  font-size: 16px;
  line-height: 1.2em;

  &:hover > input[type='radio'] {
    background: color.mix(variables.$main-green-color, white, 50%);
    border-color: color.mix(variables.$main-green-color, white, 50%);

    &:before {
      display: block;
    }
  }

  &.as-block {
    border: 1px solid color.mix(variables.$main-gray-color, white, 60%);
    border-top-color: color.mix(variables.$main-gray-color, white, 20%);
    box-sizing: border-box;
    margin-bottom: -1px !important;
    padding: 10px 16px;
    width: 100%;

    &:first-child {
      border-top-color: color.mix(variables.$main-gray-color, white, 60%);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      margin-bottom: 0 !important;
    }

    &:hover,
    &.is-selected {
      background: color.mix(variables.$main-green-color, white, 10%);
    }
  }

  .icon-area {
    .description {
      font-size: 14px;
    }

    svg {
      width: auto;
    }
  }

  .simple-area {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .description {
      font-size: 14px;
    }
  }
}

input[type='radio'] {
  appearance: none;
  border: 1px solid color.mix(variables.$main-gray-color, white, 60%);
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  height: 16px;
  margin-right: 12px;
  outline: none;
  position: relative;
  width: 16px;

  &:before {
    background: white;
    border-radius: 50%;
    content: '';
    display: none;
    height: 8px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 8px;
  }

  &:checked {
    background: variables.$main-green-color !important;
    border-color: variables.$main-green-color !important;

    &:before {
      display: block !important;
    }

    & + code,
    & + .simple,
    & + .icon-area > .description,
    & + .simple-area > .description {
      color: variables.$main-gray-color !important;
    }
  }
}
</style>
